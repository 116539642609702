export const SET_LOGGEDIN_USER = 'SET_USER';
export const SET_LOGGEDOUT_USER = 'SET_LOGGEDOUT_USER';

export const FETCH_REPORTED_USERS_REQUEST = 'FETCH_REPORTED_USERS_REQUEST';
export const FETCH_REPORTED_USERS_SUCCESS = 'FETCH_REPORTED_USERS_SUCCESS';
export const FETCH_REPORTED_USERS_FAILURE = 'FETCH_REPORTED_USERS_FAILURE';

export const DELETE_REPORTED_USER = 'DELETE_REPORTED_USER';

export const REFRESH_STATE = 'REFRESH_STATE';

export const SET_FORGET_PASSWORD_USER_EMAIL = 'SET_FORGET_PASSWORD_USER_EMAIL';
export const RESET_FORGET_PASSWORD_USER_EMAIL =
  'RESET_FORGET_PASSWORD_USER_EMAIL';

export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const SET_IS_SIGNING = 'SET_IS_SIGNING';
export const RESET_IS_SIGNING = 'RESET_IS_SIGNING';

export const FETCH_ALL_USERS_REQUEST = 'FETCH_ALL_USERS_REQUEST';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAILURE = 'FETCH_ALL_USERS_FAILURE';

export const FETCH_USER_DETAILS_REQUEST = "FETCH_USER_DETAILS_REQUEST";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";